import React, {useContext} from 'react';
import clsx from 'clsx';

import {GlobalStateContext, GlobalDispatchContext} from '../context/GlobalThemeContextProvider';
import classes from './visual-impairment-interface.module.css';

const VisualImpairmentInterface: React.FC = () => {
  const {theme, fontSize} = useContext(GlobalStateContext);
  const dispatch: React.Dispatch<{type: string}> = useContext(GlobalDispatchContext) as React.Dispatch<{type: string}>;

  return (
    <div className={classes.root}>
      <div className={classes.visual_impairment_interface}>
        <div className={classes.font_size}>
          <span className={classes.font_size_text}>Розмір шрифту</span>
          <div className={classes.buttons}>
            <button
              className={clsx(classes.btn, fontSize == 'regular' && classes.btn_selected)}
              onClick={() => dispatch({type: 'SET_REGULAR_FONT_SIZE'})}
            >
              A
            </button>
            <button
              className={clsx(classes.btn, classes.big, fontSize == 'big' && classes.btn_selected)}
              onClick={() => dispatch({type: 'SET_BIG_FONT_SIZE'})}
            >
              A
            </button>
            <button
              className={clsx(classes.btn, classes.huge, fontSize == 'huge' && classes.btn_selected)}
              onClick={() => dispatch({type: 'SET_HUGE_FONT_SIZE'})}
            >
              A
            </button>
          </div>
        </div>

        <button
          className={clsx(
            classes.theme_toggle,
            theme == 'blackOnWhite' ? classes.theme_toggle_black_on_white : classes.theme_toggle_white_on_black,
          )}
          onClick={() => dispatch({type: 'SET_REGULAR_THEME'})}
        >
          Звичайна версія сайту
        </button>

        <div className={classes.color}>
          <span className={classes.color_text}>Колір сайту</span>
          <div className={classes.buttons}>
            <button
              className={clsx(classes.btn, classes.white, theme == 'blackOnWhite' && classes.btn_selected)}
              onClick={() => dispatch({type: 'SET_BLACK_ON_WHITE_THEME'})}
            >
              A
            </button>
            <button
              className={clsx(classes.btn, classes.black, theme == 'whiteOnBlack' && classes.btn_selected)}
              onClick={() => dispatch({type: 'SET_WHITE_ON_BLACK_THEME'})}
            >
              A
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisualImpairmentInterface;
