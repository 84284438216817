import React, {useContext, useRef, useMemo, Dispatch, SetStateAction} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import clsx from 'clsx';

import {GlobalStateContext, GlobalDispatchContext} from '../context/GlobalThemeContextProvider';
import VisualImpairmentInterface from './visual-impairment-interface';
import Contacts from './contacts';
import MenuItem from './menu-item';
import classes from './menu.module.css';

type CateriesQueryResult = {
  allCategoriesYaml: {
    edges: {
      node: {
        id: string;
        title: string;
        link?: string;
        items?: {
          id: string;
          title: string;
          link: string;
        }[];
      };
    }[];
  };
};

const query = graphql`
  query {
    allCategoriesYaml {
      edges {
        node {
          id
          title
          link
          items {
            id
            title
            link
          }
        }
      }
    }
  }
`;

interface MenuProps {
  setMenuActive: Dispatch<SetStateAction<boolean>>;
}

const Menu: React.FC<MenuProps> = ({setMenuActive}) => {
  const {theme, isDesktop} = useContext(GlobalStateContext);
  const dispatch: React.Dispatch<{type: string}> = useContext(GlobalDispatchContext) as React.Dispatch<{type: string}>;
  const overlayRef = useRef(null);

  const data = useStaticQuery<CateriesQueryResult>(query);
  const categories = useMemo(() => data?.allCategoriesYaml?.edges?.map(({node}) => node) ?? [], [data]);

  const closeMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (overlayRef.current === e.target) {
      setMenuActive(false);
    }
  };

  return (
    <div onClick={closeMenu} ref={overlayRef} className={classes.overlay}>
      <div className={classes.menu}>
        <button
          className={clsx(
            classes.close_menu_icon,
            theme == 'blackOnWhite' && classes.close_menu_icon_black,
            theme == 'whiteOnBlack' && classes.close_menu_icon_white,
          )}
          onClick={() => setMenuActive(false)}
          aria-label="Закрити меню"
        ></button>

        {!isDesktop && (
          <>
            {theme == 'regular' ? (
              <button
                className={classes.visual_impairment_btn}
                onClick={() => dispatch({type: 'SET_BLACK_ON_WHITE_THEME'})}
                aria-label="Увімкнути режим для людей з порушенням зору"
              >
                Людям із порушенням зору
              </button>
            ) : (
              <VisualImpairmentInterface />
            )}

            <Contacts />
          </>
        )}

        <div className={classes.navigation}>
          {categories.map(({id, title, link, items}) => (
            <MenuItem key={id} title={title} link={link} items={items} setMenuActive={setMenuActive} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Menu;
