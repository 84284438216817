import React from 'react';
import clsx from 'clsx';

import classes from './container.module.css';

interface ContainerProps {
  className?: string;
  id?: string;
  children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({className, children}) => (
  <div className={clsx(classes.container, className)}>{children}</div>
);

export default Container;
