import React, { useContext } from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

import { GlobalStateContext } from '../context/GlobalThemeContextProvider';
import Container from './container';
import Contacts from './contacts';
import classes from './footer.module.css';

interface FooterProps {
  scrollToTop(): void;
}

const Footer: React.FC<FooterProps> = ({ scrollToTop }) => {
  const { theme } = useContext(GlobalStateContext);

  return (
    <footer className={clsx(classes.footer, theme !== 'regular' && classes.footer_black_and_white)}>
      {theme == 'regular' ? (
        <Container>
          <div className={classes.info}>
            <div className={classes.sociallink}>

              <Link to="/" className={classes.logo} aria-label="На головну сторінку" />

              <div className={classes.socilaLinkContainer}>
                <a href='https://www.facebook.com/%D0%A2%D0%9E%D0%92-%D0%A2%D0%B5%D1%80%D0%BD%D0%BE%D0%BF%D1%96%D0%BB%D1%8C%D0%B5%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BF%D0%BE%D1%81%D1%82%D0%B0%D1%87-105479172085896/?ref=pages_you_manage' className={classes.facebook} aria-label="На сторінку в facebook" />
                <a href='https://www.facebook.com/messages/t/105479172085896' className={classes.messenger} aria-label="На месенджер в facebook" />
                <a href='https://t.me/Ternopil_elektro_postach_Bot' className={classes.telegram} aria-label="На телеграм бот" />
              </div>

            </div>

            {/* <div className={classes.dividers}></div> */}

            {/* <Link to="/contacts" className={classes.call}>
              Замовити дзвінок
            </Link> */}

            <div className={classes.dividers}></div>

            <div className={classes.links}>
              <Link to="/resource.html">Ресурси</Link>
              <Link to="/normatyvna-baza.html">Нормативна база</Link>
            </div>

            <div className={classes.dividers}></div>

            <Contacts />
          </div>

          <div className={classes.end}>
            <div className={classes.download}>
              Energy Online
              <div className={classes.download_btns}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.energyonline"
                  className={classes.google_play}
                  aria-label="Завантажити мобільний додаток з Google Play"
                ></a>
                <a
                  href="https://apps.apple.com/ua/app/%D0%B5%D0%BD%D0%B5%D1%80%D0%B3%D0%B5%D1%82%D0%B8%D0%BA%D0%B0-%D0%BE%D0%BD%D0%BB%D0%B0%D0%B9%D0%BD/id1441399796?l=uk"
                  className={classes.app_store}
                  aria-label="Завантажити мобільний додаток з App Store"
                ></a>
              </div>
            </div>
            <button
              className={classes.scroll_to_top_btn}
              onClick={scrollToTop}
              aria-label="На початок сторінки"
            ></button>
          </div>
          <div className={classes.footer__copyrite}>
            <a href='https://youritsoft.com/'>© Your IT Soft </a> - <span> All Rights Reserved</span>
          </div>
        </Container>
      ) : (
        <Container className={classes.container}>
          <Link
            to="/"
            className={clsx(
              theme == 'blackOnWhite' && classes.logo_black,
              theme == 'whiteOnBlack' && classes.logo_white,
            )}
            aria-label="На головну сторінку"
          />
          <button
            className={clsx(
              classes.scroll_to_top_btn,
              theme == 'blackOnWhite' && classes.scroll_to_top_btn_black,
              theme == 'whiteOnBlack' && classes.scroll_to_top_btn_white,
            )}
            onClick={scrollToTop}
            aria-label="На початок сторінки"
          ></button>
        </Container>
      )}
    </footer>
  );
};

export default Footer;
