import React, {useContext, useState, Dispatch, SetStateAction} from 'react';
import {Link} from 'gatsby';
import clsx from 'clsx';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import classes from './menu-item.module.css';

interface MenuItemProps {
  title: string;
  link?: string;
  items?: Array<IItem>;
  setMenuActive: Dispatch<SetStateAction<boolean>>;
}

interface IItem {
  id: string;
  title: string;
  link: string;
}

const MenuItem: React.FC<MenuItemProps> = ({title, link, items, setMenuActive}) => {
  const {theme} = useContext(GlobalStateContext);
  const [isHidden, setIsHidden] = useState<boolean>(true);

  return link ? (
    link.includes('http') || link.includes('https') ? (
      <a href={link} className={classes.title_link} target="_blank">
        {title}
      </a>
    ) : (
      <Link to={link} className={classes.title_link} onClick={() => setMenuActive(false)}>
        {title}
      </Link>
    )
  ) : (
    <div className={classes.group}>
      <span
        className={clsx(
          classes.group_title,
          theme == 'blackOnWhite' && classes.group_title_close_black,
          theme == 'whiteOnBlack' && classes.group_title_close_white,
          !isHidden && theme == 'regular' && classes.group_title_open,
          !isHidden && theme == 'blackOnWhite' && classes.group_title_open_black,
          !isHidden && theme == 'whiteOnBlack' && classes.group_title_open_white,
        )}
        onClick={() => setIsHidden(prevState => !prevState)}
      >
        {title}
      </span>
      {items && (
        <div className={clsx(classes.group_links, isHidden && classes.hidden)}>
          {items.map(({id, title, link}) =>
            link.includes('http') || link.includes('https') ? (
              <a
                key={id}
                href={link}
                className={clsx(classes.link, theme == 'regular' && classes.opacity)}
                target="_blank"
              >
                {title}
              </a>
            ) : (
              <Link
                key={id}
                to={link}
                className={clsx(classes.link, theme == 'regular' && classes.opacity)}
                onClick={() => setMenuActive(false)}
              >
                {title}
              </Link>
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default MenuItem;
