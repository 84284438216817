import React, { useContext } from 'react';
import clsx from 'clsx';

import { GlobalStateContext } from '../context/GlobalThemeContextProvider';
import classes from './contacts.module.css';

const Contacts: React.FC = () => {
  const { theme } = useContext(GlobalStateContext);
  const homePhone = "0-352-52-30-09";

  return (
    <div className={classes.contacts}>
      <div
        className={clsx(
          classes.phone,
          theme == 'blackOnWhite' && classes.phone_black,
          theme == 'whiteOnBlack' && classes.phone_white,
        )}
      >
        <div>
          <a className={classes.phoneNumbers} href="tel:0800504757" target='_blank'>0-800-50-47-57</a>
        </div>
        <div className={classes.none}>{`${homePhone}`}</div>
      </div>
      <div
        className={clsx(
          classes.location,
          theme == 'blackOnWhite' && classes.location_black,
          theme == 'whiteOnBlack' && classes.location_white,
        )}
      >
        <div>м.Тернопіль,</div>
        <div>просп. Злуки, 2В</div>{' '}
      </div>
      <div
        className={clsx(
          classes.email,
          theme == 'blackOnWhite' && classes.email_black,
          theme == 'whiteOnBlack' && classes.email_white,
        )}
      >
        kanc@tepo.com.ua
      </div>
      <div
        className={clsx(
          classes.open_hours,
          theme == 'blackOnWhite' && classes.open_hours_black,
          theme == 'whiteOnBlack' && classes.open_hours_white,
        )}
      >
        <div>Пн - Чт 8:00-17:12</div>
        <div>Пт 8:00-16:12</div>
        <div>Обід 13:00-14:00</div>
      </div>
    </div>
  );
};

export default Contacts;
