import React, {Dispatch, SetStateAction, useContext, useRef, useState, useEffect} from 'react';
import {Link} from 'gatsby';
import clsx from 'clsx';

import {GlobalStateContext, GlobalDispatchContext} from '../context/GlobalThemeContextProvider';
import Container from './container';
import classes from './header.module.css';

interface HeaderProps {
  setMenuActive: Dispatch<SetStateAction<boolean>>;
}

const Header: React.FC<HeaderProps> = ({setMenuActive}) => {
  const {theme, isDesktop} = useContext(GlobalStateContext);
  const dispatch: React.Dispatch<{type: string}> = useContext(GlobalDispatchContext) as React.Dispatch<{type: string}>;
  const [openPersonalMenu, setOpenPersonalMenu] = useState<boolean>(false);
  const overlayRef = useRef(null);

  const buttonHandler = () => {
    setOpenPersonalMenu(!openPersonalMenu);
  }

  useEffect(() => {
    const checkIfClickOutSide = (e?: React.MouseEvent<HTMLElement>) => {
      if (openPersonalMenu && overlayRef.current && !overlayRef.current.contains(e?.target)) {
        setOpenPersonalMenu(false);
      }
    }

    document.addEventListener("mousedown", checkIfClickOutSide);

    return () => {
      document.removeEventListener("mousedown", checkIfClickOutSide)
    }
  }, [openPersonalMenu])

  return (
    <header className={clsx(classes.header, theme !== 'regular' && classes.header_black_and_white)}>
      <Container className={classes.content}>
        <Link
          to="/"
          className={clsx(
            classes.logo,
            theme == 'blackOnWhite' && classes.logo_black,
            theme == 'whiteOnBlack' && classes.logo_white,
          )}
          aria-label="На головну сторінку"
        />

        {isDesktop && (
          <div className={classes.btns}>
            <a href="https://tepss.utilysuite.com/" className={classes.both_personal__accounts}>
              <button className={clsx(
                  classes.personal_account,
                  classes.outlined_btn,
                  theme == 'blackOnWhite' && classes.personal_account_black,
                  theme == 'whiteOnBlack' && classes.personal_account_white,
              )}>
                Персональний кабінет
              </button>
              {/*<div className={clsx(*/}
              {/*    theme == 'blackOnWhite' || theme == 'whiteOnBlack' ? classes.both_personal__accounts_black_and_white : classes.both_personal__accounts_normal,*/}
              {/*)}>*/}
              {/*  Персональний кабінет працює в тестовому режимі .<br/> В зв`язку з цим,  можливе некоректне відображення інформацї. <br/> Вибачте за тимчасові незручності .*/}
              {/*</div>*/}
            </a>
            {/*<button*/}
            {/*  ref={overlayRef} */}
            {/*  onClick={buttonHandler}*/}
            {/*  className={clsx(*/}
            {/*    !openPersonalMenu ? classes.personal_account : classes.personal_account__active,*/}
            {/*    classes.outlined_btn,*/}
            {/*    theme == 'blackOnWhite' && classes.personal_account_black,*/}
            {/*    theme == 'whiteOnBlack' && classes.personal_account_white,*/}
            {/*  )*/}
            {/*}>*/}
            {/*  <div*/}
            {/*    className={*/}
            {/*      openPersonalMenu*/}
            {/*        ? clsx(*/}
            {/*          theme === 'regular' && classes.prs_menu__active,*/}
            {/*          theme === 'blackOnWhite' && classes.prs_menu__active__white,*/}
            {/*          theme === 'whiteOnBlack' && classes.prs_menu__active__black*/}
            {/*        )*/}
            {/*        : clsx(classes.prs_menu)*/}
            {/*    }*/}
            {/*  >*/}
            {/*    <a*/}
            {/*      href="https://my.toe.com.ua"*/}
            {/*      className={clsx(*/}
            {/*        classes.prs_menu_link,*/}
            {/*        theme == 'blackOnWhite' && classes.prs_menu_link__white,*/}
            {/*        theme == 'whiteOnBlack' && classes.prs_menu_link__black*/}
            {/*      )}*/}
            {/*    >*/}
            {/*      Побутовим споживачам*/}
            {/*    </a>*/}
            {/*    <a*/}
            {/*      href="https://tepss.utilysuite.com/"*/}
            {/*      className={clsx(*/}
            {/*        classes.prs_menu_link,*/}
            {/*        theme == 'blackOnWhite' && classes.prs_menu_link__white,*/}
            {/*        theme == 'whiteOnBlack' && classes.prs_menu_link__black*/}
            {/*      )}*/}
            {/*    >*/}
            {/*      Непобутовим споживачам*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*  Персональний кабінет*/}
            {/*</button>*/}
            

            {theme == 'regular' && (
              <button
                className={clsx(classes.visual_impairment_btn, classes.outlined_btn)}
                onClick={() => dispatch({type: 'SET_BLACK_ON_WHITE_THEME'})}
                aria-label="Увімкнути режим для людей з порушенням зору"
              >
                <div>
                  Людям з порушенням зору
                </div>
              </button>
            )}
          </div>
        )}

        <div className={clsx(classes.links, theme !== 'regular' && classes.links_black_and_white)}>
          <Link to="/pro-nas.html" className={classes.link}>
            Про компанію
          </Link>
          <Link to="/press-center" className={classes.link}>
            Прес-центр
          </Link>
          {/*<a href="http://services.tepo.com.ua/" className={classes.link}>*/}
          {/*  Сервіси*/}
          {/*</a>*/}
        </div>

        <button
          className={clsx(
            classes.menu_btn,
            theme == 'blackOnWhite' && classes.menu_btn_black,
            theme == 'whiteOnBlack' && classes.menu_btn_white,
          )}
          onClick={() => {
            setMenuActive(true);
          }}
          aria-label="Відкрити меню"
        ></button>
      </Container>
    </header>
  );
};

export default Header;
