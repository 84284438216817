import React, {useState, useContext} from 'react';
import clsx from 'clsx';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import VisualImpairmentInterface from './visual-impairment-interface';
import Header from './header';
import Menu from './menu';
import Footer from './footer';
import './layout.css';
import '@fontsource/roboto';
import classes from './layout.module.css';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({children}) => {
  const {theme, fontSize, isDesktop} = useContext(GlobalStateContext);
  const [menuActive, setMenuActive] = useState<boolean>(false);

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <div
      className={clsx(
        classes.root,
        menuActive && classes.fixed,
        theme == 'blackOnWhite' && classes.black_on_white,
        theme == 'whiteOnBlack' && classes.white_on_black,
        fontSize == 'big' && classes.big_font_size,
        fontSize == 'huge' && classes.huge_font_size,
      )}
    >
      {isDesktop && theme !== 'regular' && <VisualImpairmentInterface />}

      <Header setMenuActive={setMenuActive} />

      {menuActive && <Menu setMenuActive={setMenuActive} />}

      <main className={classes.main}>{children}</main>

      <Footer scrollToTop={scrollToTop} />
    </div>
  );
};

export default Layout;
